import {
    ComponentRef,
    ElementRef,
    Injectable,
    InjectionToken,
    StaticProvider,
    Type,
    ViewContainerRef
} from "@angular/core";
import {ComponentFactoryResolver} from "@angular/core";
import {ApplicationRef} from "@angular/core";
import {Injector} from "@angular/core";
import {EmbeddedViewRef} from "@angular/core";
//
import {SmpCompRefs, SmpCompData} from "../models/public";
//
import {Observable, timer} from "rxjs";

@Injectable()
export class SmpDomService {

    private _refs: SmpCompRefs = {};

    constructor(private _componentFactoryResolver: ComponentFactoryResolver,
                private _appRef: ApplicationRef,
                private _injector: Injector) {
    }

    appendComp<T = any>(component: Type<T>, key?: string,
                        target: SmpCompData["target"] = "body",
                        injector: Injector = this._injector,
                        waiter: Observable<void | number> = timer()): ComponentRef<T> {
        // 1. Create a component reference from the component
        const componentRef = this._componentFactoryResolver
        .resolveComponentFactory(component as Type<T>)
        .create(injector);

        // 2. Attach component to the appRef so that it's inside the ng component tree
        this._appRef.attachView(componentRef.hostView);

        // 3. Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        if (!!key) {
            !!this._refs[key] && console.warn("A ref with this key already exists and will be overwritten");
            this._refs[key] = componentRef;
        }
        else {
            console.warn("Component appended, but key wasn't provided. It won't be possible to remove it from the DOM automagically");
        }

        waiter.subscribe(() => {
            // 4. Append DOM element to the body
            const targetEl = (typeof target === typeof ""
                ? document.querySelector(target as string)
                : target instanceof ElementRef ? target.nativeElement : target) || document.body;
            targetEl.appendChild(domElem);
        });

        return componentRef as ComponentRef<T>;
    }

    appendComps(compsData: SmpCompData[], injector?: Injector, waiter?: Observable<void | number>): void {
        compsData.forEach((compData) => this.appendComp(compData.comp, compData.key, compData.target, injector, waiter));
    }

    createInjector<T = any>(token: InjectionToken<T>, value: T, viewContainerRef?: ViewContainerRef): Injector {
        const providers: StaticProvider[] = [
            {provide: token, useValue: value}
        ];

        return Injector.create({
            parent: viewContainerRef ? viewContainerRef.injector : this._injector,
            providers
        });
    }

    removeComp(key: string): boolean {
        if (!!this._refs[key]) {
            const componentRef = this._refs[key];
            this._appRef.detachView(componentRef.hostView);
            componentRef.destroy();

            return !0;
        }
        else {
            console.warn(`No ref found with key ${key}`);

            return !1;
        }
    }

}
