import {ModuleWithProviders, NgModule} from "@angular/core";
//
import {SmpDomService} from "./services/public";

@NgModule({})
export class SmpCoreUtilsModule {

    static forRoot(): ModuleWithProviders<SmpCoreUtilsModule> {
        return {
            ngModule: SmpCoreUtilsModule,
            providers: [
                SmpDomService
            ]
        };
    }
}
