import { NgModule } from '@angular/core';
import { NgxEasypiechartComponent } from './ngx-easypiechart.component';

@NgModule({
  imports: [
  ],
  declarations: [NgxEasypiechartComponent],
  exports: [NgxEasypiechartComponent]
})
export class NgxEasypiechartModule { }
