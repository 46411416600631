import {Injectable} from "@angular/core";
import {NativeDateAdapter} from "@angular/material/core";
import {MatDateFormats} from "@angular/material/core/datetime/date-formats";
//
import {SmpDateDisplay} from "../models/smp-date-display.model";
//
import {includes} from "lodash";

const smpSep: string = "/";
const smpDay: string = "d";
const smpDayDoubled: string = "dd";
const smpMonth: string = "M";
const smpMonthDoubled: string = "MM";
const smpYear: string = "yyyy";
const smpCurrentYear: number = new Date().getFullYear();
const smpCentury: number = Math.round(smpCurrentYear / 100);

export const SMP_LOCALE_IT_IT: string = "it-IT";
export const SMP_LOCALE_EN_GB: string = "en-GB";
export const SMP_LOCALE_EN_US: string = "en-US";

export const SMP_FORMATS = {
    "it-IT": `${smpDayDoubled}${smpSep}${smpMonthDoubled}${smpSep}${smpYear}`,
    "en-GB": `${smpDayDoubled}${smpSep}${smpMonthDoubled}${smpSep}${smpYear}`,
    "en-US": `${smpMonth}${smpSep}${smpDay}${smpSep}${smpYear}`,
};

export const SMP_DATE_FORMATS_IT: MatDateFormats = {
    parse: {
        dateInput: {month: "numeric", year: "numeric", day: "numeric"}
    },
    display: {
        dateInput: SMP_FORMATS[SMP_LOCALE_IT_IT],
        monthYearLabel: `${smpMonthDoubled}${smpSep}${smpYear}`,
        dateA11yLabel: SMP_FORMATS[SMP_LOCALE_IT_IT],
        monthYearA11yLabel: `${smpMonthDoubled}${smpSep}${smpYear}`
    }
};

export const SMP_DATE_FORMATS_EN_GB: MatDateFormats = {
    parse: {
        dateInput: {month: "numeric", year: "numeric", day: "numeric"}
    },
    display: {
        dateInput: SMP_FORMATS[SMP_LOCALE_EN_GB],
        monthYearLabel: `${smpMonthDoubled}${smpSep}${smpYear}`,
        dateA11yLabel: SMP_FORMATS[SMP_LOCALE_EN_GB],
        monthYearA11yLabel: `${smpMonthDoubled}${smpSep}${smpYear}`
    }
};

export const SMP_DATE_FORMATS_EN_US: MatDateFormats = {
    parse: {
        dateInput: {month: "numeric", year: "numeric", day: "numeric"}
    },
    display: {
        dateInput: SMP_FORMATS[SMP_LOCALE_EN_US],
        monthYearLabel: `${smpMonth}${smpSep}${smpYear}`,
        dateA11yLabel: SMP_FORMATS[SMP_LOCALE_EN_US],
        monthYearA11yLabel: `${smpMonth}${smpSep}${smpYear}`
    }
};

@Injectable()
export class SmpMatDateLocale extends NativeDateAdapter {

    get currentLocale(): string {
        return this.locale;
    }

    display: SmpDateDisplay = SMP_DATE_FORMATS_EN_GB.display;

    format(date: Date, _displayFormat_: string): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        switch (this.display.dateInput) {
            case SMP_DATE_FORMATS_IT.display.dateInput:
                return this._addLeadingZeroes(day) + smpSep + this._addLeadingZeroes(month) + smpSep + year;
            case SMP_DATE_FORMATS_EN_GB.display.dateInput:
                return this._addLeadingZeroes(day) + smpSep + this._addLeadingZeroes(month) + smpSep + year;
            case SMP_DATE_FORMATS_EN_US.display.dateInput:
                return month + smpSep + day + smpSep + year;
            default:
                let formattedDate = "";
                try {
                    formattedDate = date.toLocaleDateString(SMP_LOCALE_EN_GB);
                } catch (e) {
                    console.warn("Catched error while printing ", e);
                    formattedDate = date.toDateString();
                }

                return formattedDate;
        }
    }

    parse(value: any): Date | null {
        // dd/MM/yyyy
        if (includes([SMP_DATE_FORMATS_IT.display.dateInput, SMP_DATE_FORMATS_EN_GB.display.dateInput], this.display.dateInput)) {
            if (typeof value === typeof "" && value.length > 0) {
                const str = value.split(smpSep);
                if (str.length < 3) {
                    return new Date("smp");
                }
                if (str[2].length < 4) {
                    str[2] = smpCentury + str[2];
                }
                if (str[2].length !== 4) {
                    return new Date("smp");
                }
                const year = +str[2];
                const month = +str[1] - 1;
                const day = +str[0];

                return new Date(year, month, day);
            }

            return null;
        }
        // M/d/yyyy
        else if (this.display.dateInput === SMP_DATE_FORMATS_EN_US.display.dateInput) {
            if (typeof value === typeof "" && value.length > 0) {
                const str = value.split(smpSep);
                if (str.length < 3) {
                    return new Date("smp");
                }
                if (str[2].length < 4) {
                    str[2] = smpCentury + str[2];
                }
                if (str[2].length !== 4) {
                    return new Date("smp");
                }
                const year = +str[2];
                const month = +str[0] - 1;
                const day = +str[1];

                return new Date(year, month, day);
            }

            return null;
        }
        else {
            console.warn("Date input format not valid." +
                " Be sure to use SMP_DATE_FORMATS" +
                " or extend this class with your own 'parser' and format 'methods'");
            console.info("For a complete guide visit github.com/tonysamperi/ngx-mat-lib");

            return null;
        }
    }

    setLocale(locale: any) {
        switch (locale) {
            case SMP_LOCALE_IT_IT:
                this.display = SMP_DATE_FORMATS_IT.display;
                break;
            case SMP_LOCALE_EN_GB:
                this.display = SMP_DATE_FORMATS_EN_GB.display;
                break;
            case SMP_LOCALE_EN_US:
                this.display = SMP_DATE_FORMATS_EN_US.display;
                break;
            default:
                this.display = SMP_DATE_FORMATS_EN_GB.display;
                break;
        }
        super.setLocale(locale);
    }

    private _addLeadingZeroes(n: number) {
        return ("0" + n).slice(-2);
    }
}
