import {Component, EventEmitter, OnDestroy, Input, Output, ElementRef, AfterViewInit} from "@angular/core";
//
import {NgxMatTimepickerClockFace} from "../../models/ngx-mat-timepicker-clock-face.interface";
import {NgxMatTimepickerUnits} from "../../models/ngx-mat-timepicker-units.enum";
import {NgxMatTimepickerParserPipe} from "../../pipes/ngx-mat-timepicker-parser.pipe";
import {NgxMatTimepickerUtils} from "../../utils/ngx-mat-timepicker.utils";

function retainSelection() {
    this.selectionStart = this.selectionEnd;
}

@Component({
    selector: "ngx-mat-timepicker-dial-control",
    templateUrl: "ngx-mat-timepicker-dial-control.component.html",
    styleUrls: ["ngx-mat-timepicker-dial-control.component.scss"],
    providers: [NgxMatTimepickerParserPipe]
})
export class NgxMatTimepickerDialControlComponent implements AfterViewInit, OnDestroy {

    private get _selectedTime(): NgxMatTimepickerClockFace {
        if (!!this.time) {
            return this.timeList.find(t => t.time === +this.time);
        }
    }

    @Input()
    disabled: boolean;

    @Output()
    focused = new EventEmitter<void>();

    @Input()
    isActive: boolean;

    @Input()
    isEditable: boolean;

    @Input()
    minutesGap: number;

    previousTime: number | string;

    @Input()
    time: string;

    @Output()
    timeChanged = new EventEmitter<NgxMatTimepickerClockFace>();

    @Input()
    timeList: NgxMatTimepickerClockFace[];

    @Input()
    timeUnit: NgxMatTimepickerUnits;

    @Output()
    timeUnitChanged = new EventEmitter<NgxMatTimepickerUnits>();

    @Output()
    unfocused = new EventEmitter<void>();

    constructor(private _elRef: ElementRef, private _timeParserPipe: NgxMatTimepickerParserPipe) {
    }

    changeTimeByKeyboard(e: any): void {
        const char = String.fromCharCode(e.keyCode);

        if (isTimeDisabledToChange(this.time, char, this.timeList)) {
            e.preventDefault();
        }
    }

    ngAfterViewInit(): void {
        this._elRef.nativeElement.querySelector("input").addEventListener("select", retainSelection, false);
    }

    ngOnDestroy(): void {
        this._elRef.nativeElement.querySelector("input").removeEventListener("select", retainSelection);
    }

    onKeydown(e: any): void {
        if (!NgxMatTimepickerUtils.isDigit(e)) {
            e.preventDefault();
        }
        else {
            this._changeTimeByArrow(e.keyCode);
        }
    }

    onModelChange(value: string): void {
        this.time = this._timeParserPipe.transform(value, this.timeUnit);
    }

    saveTimeAndChangeTimeUnit(event: FocusEvent, unit: NgxMatTimepickerUnits): void {
        event.preventDefault();
        this.previousTime = this.time;
        this.timeUnitChanged.next(unit);
        this.focused.next();
    }

    updateTime(): void {
        if (this._selectedTime) {
            this.timeChanged.next(this._selectedTime);
            this.previousTime = this._selectedTime.time;
        }
    }

    private _addTime(amount: number): string {
        return `0${+this.time + amount}`.substr(-2);
    }

    private _changeTimeByArrow(keyCode: number): void {
        let time: string;

        // arrow up
        if (keyCode === 38) {
            time = this._addTime(this.minutesGap || 1);
        }
        // arrow down
        else if (keyCode === 40) {
            time = this._addTime(-1 * (this.minutesGap || 1));
        }

        if (!isTimeUnavailable(time, this.timeList)) {
            this.time = time;
            this.updateTime();
        }
    }

}

function isTimeDisabledToChange(currentTime: string, nextTime: string, timeList: NgxMatTimepickerClockFace[]): boolean {
    const isNumber = /\d/.test(nextTime);

    if (isNumber) {
        const time = currentTime + nextTime;

        return isTimeUnavailable(time, timeList);
    }
}

function isTimeUnavailable(time: string, timeList: NgxMatTimepickerClockFace[]): boolean {
    const selectedTime = timeList.find(value => value.time === +time);

    return !selectedTime || (selectedTime && selectedTime.disabled);
}
